<template>
  <div class="div">
    <nav class="navbar navbar-inverse navbar-fixed-top">
      <div class="container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="#" id="logoI">
            <img
              src="/img/ss.jpg"
              
            />
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav btns navbar-center">
            <li :class="{ active: node == 0 }">
              <a href="/">博客 <span class="sr-only"></span></a>
            </li>
            <li :class="{ active: node == 1 }"><a href="/life" target="_self">生活</a></li>
            <li :class="{ active: node == 2 }"><a href="/game" target="_self">游戏</a></li>
            <li><a href="#" target="_self">待定...</a></li>
          </ul>
          <form class="navbar-form navbar-left visible-lg-block">
            <div class="flex">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Search" />
              </div>
              <button type="submit" class="btn btn-default plB">提交</button>
            </div>
          </form>
          <form class="navbar-form navbar-right visible-lg-block">
            <div class="flex">
              <button
                type="button"
                v-if="Login"
                class="btn navbar-btn navbar-right myBtn plB"
                @click="dl"
              >
                登录/注册
              </button>
              <!-- <button
                type="button"
                v-if="Login"
                class="btn navbar-btn navbar-right myBtn plB"
                @click="zc"
              >
                注册
              </button> -->
              <div v-else class="User">
                <div>用户:{{ User }}</div>
                <button
                  type="button"
                  class="btn navbar-btn navbar-right myBtn"
                  style="margin-left: 2vw"
                  @click="tc"
                >
                  退出
                </button>
                <button v-if="this.limit < 2" @click="htgl"  type="button"
                  class="btn navbar-btn navbar-right myBtn"
                  style="margin-left: 20px">进入后台</button>
              </div>
            </div>
          </form>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
  </div>
</template>

<script>
import { network } from "../assets/js/network";
export default {
  data() {
    return {
      Login: true,
      User: "",
      limit: "",
    };
  },
  methods: {
    zc() {
      window.location.href = "/zc";
    },
    dl() {
      window.location.href = "/dl";
    },
    tc() {
      network({
        url: "/tc",
        methed: "get",
      }).then((res) => {
        // console.log(res.data.code);
        if (res.data.code == 0) {
          window.location.href = "/";
          (this.Login = true), (this.User = "");
        } else {
          this.Login = false;
        }
      });
    },
    htgl() {
      window.location.href = "/htgl?user=" + this.User + "&limit=" + this.limit;
    },
  },
  mounted() {
    var bodys = document.getElementsByClassName("plB");
    $(bodys).css("cursor", "url(/img/d.ico) ,move");
    network({
      url: "/get",
      methed: "get",
    }).then((res) => {
      console.log(res.data.code);
      if (res.data.code != 0) {
        (this.Login = false), (this.User = res.data.code.user);
        this.limit = res.data.code.limit;
        this.$emit("logins", { code: this.User,
         login:this.Login
         });
      } else {
        this.Login = true;
        this.$emit("logins", { code: false });
      }
    });
  },
  props:{
    node:{
      type:Number,
      default:0
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  min-height: 0.65rem;
   height: 50px;
}
.navbar-brand img {
  max-width: auto;
  max-height: 100%;
  display: block;
}
.navbar .navbar-collapse .phone li a {
  color: #fff;
}
.btns li {
  font-size: 0.40875rem;
  height: 50px;
  margin-left: 3vw;
}
.btns li a {
  height: 100% !important;
  padding-top: 0;
  padding-bottom: 0;
  /* line-height: 1.015625rem; */
  line-height: 50px;
}
.navbar-header {
  /* height: 1.015625rem !important; */
   height: 50px;
  width: 160px;
}
.navbar-brand {
  height: 100%;
}
.navbar-form {
  margin: 0;
  padding: 0;
}
.navbar-form .form-group {
  margin-right: 1vw;
}
.navbar-form .form-group .form-control {
  width: 15vw;
  padding: 0;
  height: 0.609375rem;
  margin: 0 auto;
}
.flex {
  display: flex;
  align-items: center;
  height: 1.015625rem;
   height: 50px;
}
.navbar-right .flex {
  /* padding: 0; */
  margin: 0;
  margin-right: 3vw;
  font-size: 25px;
  font-weight: 800;
}
.navbar {
  background-color: #1f1e1e;
}
.User {
  width: 100%;
  font-size: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-weight: 600; */
}
.flex > button {
  margin-left: 1.5vw;
}
#logoI{
 margin-left: 0;
 padding: 0;
}
.myBtn{
  color: #1f1e1e;
}
</style>