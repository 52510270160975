<template>
  <div>
    <ul class="xlkUl">
      <a :href="'/about?id='+item.article_id +'&user='+isLogin.code" v-for="(item, i) in data" :key="i"><li>{{i+1}}.{{item.title}}</li></a>
      
    </ul>
  </div>
</template>
<script>
import { network } from "../assets/js/network";
export default {
  props: {
    classify: {
      type: String,
      default: "",
    },
    isLogin:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  data(){
      return{
        data:''
      }
  },
  methods: {},
  mounted() {
    // console.log(this.classify);
    if (this.classify.length != 0) {
      network({
        url: "/dhl?classify=" + this.classify,
      }).then((values) => {
        // console.log(values);
        this.data=values.data
        // console.log(this.data)
      });
    }
  },
  //   watch: {
  //     classify: function (newData, oldData) {
  //         console.log(newData,oldData,55555)
  //       if (newData.length != 0) {
  //         console.log(this.classify);
  //         network({
  //           url: "/dhl?classify=" + this.classify,
  //         }).then((values) => {
  //           console.log(values);
  //         });
  //       }
  //     },
  //   },
};
</script>
<style>
.xlkUl {
  margin-left: 10%;
  margin-right: 10%;
  list-style: none;
  padding-left: 10%;
  margin: 0px;
  font-size: 20px;
  color: silver;
}
/* 包含以下五种的链接*/
.xlkUl a {
  text-decoration: none;
  color: #222226;
}
.xlkUl a:hover {
  text-decoration: none;
  color: red;
}
</style>