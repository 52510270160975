<template>
  <!-- <a href="#"> -->
  <div class="essay" @click="uls">
    <!-- <div style="width:100px,height:100%">1</div> -->
    <div class="pozis">
      <div class="essayHead">
        <div class="bg missAngle">
          <span>{{ this.item.classfiy }}</span>
        </div>
        <h3>{{ this.item.title }}</h3>
      </div>
      <div class="content padding">
        <div class="ecText">
          <div>
            <p>{{ this.item.content }}</p>
          </div>
        </div>
        <!-- <div class="ecImg ">
              <img class="img-responsive" src="https://img-blog.csdnimg.cn/20210720160340210.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L2FfbGl0dGxlX25pbmU=,size_16,color_FFFFFF,t_70" alt="">
          </div> -->
      </div>
      <div class="bottom padding">
        <div class="ebLeft">
          <div class="ebLefCk le">
            <i class="ebLefCkImg"></i>
            <span class="ebLefCkText">{{ this.item.views }}</span>
          </div>
          <div class="ebLefCk">
            <i class="ebLefDzImg"></i>
            <span class="ebLefCkText">{{ this.item.like_count }}</span>
          </div>
          <div class="ebLefCk">
            <i class="ebLefDplmg"></i>
            <span class="ebLefCkText">{{ this.item.comment_count }}</span>
          </div>
        </div>
        <div class="ebRight">
          <p>{{ this.item.date }}</p>
        </div>
      </div>
      <div class="fgx"></div>
    </div>
  </div>
  <!-- </a> -->
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: function(){
        return {}
      }
    },
    i: {
      type: Number,
      default: -1,
    },
    isLogin:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  mounted() {
    var body = document.getElementsByClassName("essay");
    $(body).css("cursor", "url(/img/d.ico),move");
    var bodys = document.getElementsByClassName("essay")[this.i];
    $(bodys).css("animation-delay", 0.75 * (this.i + 1) + "s");
  },
  methods: {
    uls() {
      // console.log(this.item.article_id, 5555555);
      window.open("/about?id=" + this.item.article_id+'&user='+this.isLogin.code)
    },
  },
};
</script>
<style>
.padding {
  padding-bottom: 1vh;
  padding-top: 1vh;
}
.essay {
  position: relative;
  width: 97% !important;
  margin-left: 1.5%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
  border-radius: 10px;
  /* animation: tran calc(var(--foo) * 1s); */
  opacity: 0;
  animation-name: tran, ocupy;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  /* animation-delay: 2s; */
}
@keyframes ocupy {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tran {
  0% {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0px);
  }
}
.essay > div{
  /* position: absolute; */
  background-color: #fff;
}
.essay .essayHead {
  width: 94%;
  margin-left: 3%;
  text-align: left;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  /* flex: 15; */
}
.essayHead > h3 {
  margin: 0 !important;
  height: auto;
  line-height: 40px;
  margin-left: 10px;
}
.essay .content {
  width: 100%;
  /* flex: 70; */
  display: flex;
}
.ecImg {
  flex: 2;
}
.essay .bottom {
  /* flex: 15; */
  width: 94%;
  margin-left: 3%;
  display: flex;
}
.ecImg .img-responsive {
  position: relative;
  top: 25%;
}
.ecText {
  flex: 9;
  text-indent: 2em;
}
.ecText div {
  font-size: 0.28125rem;
  color: #999aaa;
  width: 94%;
  margin-left: 3%;
}
.ecText p {
  margin-bottom: 0;
}
.ebLefCkImg {
  display: inline-block;
  width: 0.28125rem;
  height: 0.28125rem;
  background-position: center center;
  background: url("../assets/img/ck.png") no-repeat center;
  vertical-align: middle;
  margin-right: 0.125rem;
  background-size: 0.25rem !important;
}
.ebLeft {
  flex: 7;
  display: flex;
  align-items: center;
}
.ebLeft .ebLefCk {
  margin-left: 3vw;
}
.le {
  margin-left: 0 !important;
}
.ebRight {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ebRight p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.21875rem;
  color: #000;
}
.ebLefCkText {
  font-size: 0.21875rem;
  vertical-align: middle;
  color: #999aaa;
}
.ebLefDzImg {
  display: inline-block;
  width: 0.28125rem;
  height: 0.28125rem;
  background-position: center center;
  background: url("../assets/img/dz.png") no-repeat center;
  vertical-align: middle;
  margin-right: 0.125rem;
  background-size: 0.25rem !important;
}
.ebLefDplmg {
  display: inline-block;
  width: 0.28125rem;
  height: 0.28125rem;
  background-position: center center;
  background: url("../assets/img/pl.png") no-repeat center;
  vertical-align: middle;
  margin-right: 0.125rem;
  background-size: 0.25rem !important;
}
.fgx {
  width: 100%;
  height: 2px;
  background-color: #e8e8ee;
}
.bg {
  /* width: 70px; */
  height: auto;
  background: rgb(94, 179, 9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
}
.foldingAngle {
  background: linear-gradient(
        to left bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.4) 0
      )
      no-repeat 100% 0 / 1.4em 1.4em,
    linear-gradient(-135deg, transparent 1em, rgb(94, 179, 9) 0);
}
.missAngle {
  background: linear-gradient(-45deg, transparent 5px, #58a 0);
}
.bg span {
  color: #fff;
}
.a {
  text-decoration: none;
  color: #222226;
}
.a:hover {
  text-decoration: none;
}
.missAngle h3 {
  font-weight: 500 !important;
}
</style>