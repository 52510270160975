<template>
  <div class="time">
    <h3><p>推荐文章</p></h3>
    <div class="tj-item nopad" id="tja" v-for="(item, i) in data" :key="i">
      <a
        :href="'about?id=' + item.article_id +'&user='+isLogin.code"
        style="text-decoration: none"
        id="tja"
        >{{ item.title }}</a
      >
      <div>
        <span>阅读量 {{ item.views }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { network } from "../assets/js/network";
export default {
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    network({
      url: "/sal",
    }).then((values) => {
      // console.log(values);
      this.data = values.data;
    });
    var body = document.getElementById('tja');
    // console.log(body);
    $(body).css("cursor", "url(/img/d.ico) 32 32 ,move");
  },
  props:{
    isLogin:{
      type:Object,
      default:function(){
        return {}
      }
    }
  }
};
</script>
<style>
.time {
  width: 90%;
  height: 100%;
  margin-left: 5%;
}
.time > h3 {
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  padding-bottom: 0;
  position: relative;
  margin: 0;
}
.time > h3 p {
  padding-bottom: 0;
}
.time > h3::before {
  position: absolute;
  content: "";
  z-index: 2;
  width: 6px;
  height: 22px;
  top: 18%;
  left: -1%;
  background: #ec7259;
}
.tj-item a:link {
  text-decoration: none;
}
.tj-item a {
  color: #000 !important;
  font-size: 22px;
  word-wrap: break-word;
}
.tj-item div {
  font-size: 14px;
  color: #969696;
  letter-spacing: 1px;
}
.tj-item a:visited {
  text-decoration: none;
}
.tj-item {
  margin-top: 10px;
}
.nopad {
  margin: 0;
  z-index: 10;
}
</style>