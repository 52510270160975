<template>
  <div>
    <ul class="list-unstyled">
      <li v-for="(item, i) in ppp" :key="i">
        <div class="allXlk" :style="{ 'max-height': height,'cursor':'url(/img/d.ico),move' }" @click="flags(i)">
          <div class="marginLeft">
            <div class="xlk">
              <div>{{item.classify}}</div>
              <div  :id="i" class="xlkBtn">
                <span
                  :class="
                    ppp[i].code
                      ? 'glyphicon glyphicon-triangle-bottom'
                      : 'glyphicon glyphicon-triangle-top'
                  "
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
          <div class="xlkUls" v-show="ppp[i].code === false">
             <times :classify="item.classify" :isLogin="isLogin"></times>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { network } from "../assets/js/network";
import times from "./ul.vue";
export default {
  data() {
    return {
      ppp:'',
      height: 35 + "px",
      datas: [],
    };
  },
  components:{
   times
  },
  methods: {
    flags(i) {
      this.ppp[i].code = !this.ppp[i].code;
      for (let item in this.ppp) {
        if (item != i) {
          this.ppp[item].code = true;
        }
      }
      this.heights(i);
    },
    heights(i) {
      if (this.ppp[i].code === false) {
        setTimeout(() => {
          let h = document.getElementsByClassName("xlkUls")[i];
          this.height = h.scrollHeight + 35 + "px";
        }, 1);
      } else {
        this.height = 35 + "px";
      }
    },
  },
  mounted() {
    // var body = document.getElementsByClassName("allXlk");
    // $(body).css("cursor", "url(http://localhost:3000/img/d.ico) 32 32 ,move");

    network({
      url: "/wzfl",
    }).then((values) => {
      // console.log(values.data);
      for (let index = 0; index < values.data.length; index++) {
        values.data[index].code = true;
        // console.log(values.data[index]);
      }
      this.ppp = values.data;
    });
  },
  props:{
    isLogin:{
      type:Object,
      default:function(){
        return {}
      }
    }
  }
};
</script>
<style>
.list-unstyled > li {
  /* max-height: 35px; */
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
}
.list-unstyled > li button {
  background: none;
  border: none;
}
.list-unstyled > li > div {
  font-size: 23px;
  text-align: left;
}
.allXlk {
  width: 100%;
  overflow: hidden;
  /* max-height: 35px; */
  transition: max-height 0.3s;
}
.marginLeft {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.xlk {
  display: flex;
  justify-content: space-between;
}
.xlk li {
  border: none;
  border-bottom: snow;
  margin-bottom: 10px;
  background: red;
}
/* .xlkBtn{
  cursor: auto;
} */
</style>