import axios from "axios";
export function network(config) {
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            // baseURL: "www.tengzin.cn",
            // baseURL:'http://localhost:3001',
            timeout: 60000,
            withCredentials: true
        })
        instance(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}