<template>
  <div>
    <div class="ot">
      <div class="col-md-8">
        <div class="otBorder">
          <div v-if="code === 1">
            <essay
              v-for="(item, i) in data"
              :key="i"
              :item="item"
              :i="i"
              :isLogin="isLogin"
             class="essay"></essay>
          </div>
          <div v-if="code === 0" class="component">
            <texts></texts>
          </div>
        </div>
      </div>
      <div class="col-md-4 visible-lg-block">
        <lefts class="indexLeft" :code="Nums" :isLogin="isLogin"></lefts>
      </div>
    </div>
  </div>
</template>
<script>
import essay from "./essay.vue";
import lefts from "./left.vue";
import texts from "./text.vue";
import { times } from "../assets/js/time";
import { network } from "../assets/js/network";
var marked = require("marked");
export default {
  components: {
    essay,
    lefts,
    texts,
  },
  props: {
    code: {
      type: Number,
      default: 1,
    },
    isLogin:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  data() {
    return {
      data: [],
      Nums:1
    };
  },
  mounted() {
    network({
      url: "/index?code=0",
    }).then((values) => {
      // console.log(values.data);
      this.data = values.data;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].date = times(values.data[i].date, 1);
        let str = marked(values.data[i].content) + "";
        str = str.replace(/<[^>]*>|<\/[^>]*>/gm, "");

        str=str.slice(0, 59);
        // console.log(str,i);
        this.data[i].content=str+'...'
      }
    });
  },
};
</script>
<style>
.ot {
  width: 80vw;
  margin-left: 10vw;
}
.col-md-8 {
  /* background: #fff; */
  padding: 0 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.col-md-4 {
  height: 100%;

  padding: 0 !important;
}
.otBorder {
  margin-top: 0.38125rem;
  /* background: #fff; */
  width: 96%;
  margin-left: 2%;
  /* border: .015625rem solid #fff; */
  box-sizing: border-box;
  min-height: 800px;
}
.actives {
  margin-top: 0;
}
.component {
  height: 100%;
}
/* .essay{
cursor:url('http://localhost:3000/img/dj.ico')!important;
} */
</style>