<template>
  <div class="tttt">
    <div class="indexBottom">
      <div class="bottomLeft">
        <div class="LeftTitle">
          <h3>关于本站</h3>
          <p>
            关于这个人博客，我是希望将它做大，分享技术博客，展现生活风貌，实现一些小游戏，甚至打算将来把一些英音乐，视频等文件
            传入到服务器，可以进行点播
          </p>
        </div>
      </div>
      <div class="bottomRight">
        <div class="RightTitle">
          <h3>备案信息</h3>
          <p>www.tengzin.cn All Rights Reserved.</p>
          <p>备案号: <u>渝:ICP备2021009127号</u></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style>
.indexBottom {
  width: 100%;
  height: 2.25rem;
  background-color: rgb(88, 81, 82);
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.bottomLeft {
  flex: 1;
  display: flex;
  align-items: center;
}

.bottomRight {
  flex: 1;
  display: flex;
  align-items: center;
}
.bottomLeft h3 {
  color: #999;
  font-size: 0.3rem;
}
.bottomLeft p {
  color: #666;
  font-size: 0.25rem;
}
.LeftTitle {
  width: 50%;
  margin-left: 40%;
}
.bottomRight h3 {
  color: #999;
  font-size: 0.3rem;
}
.bottomRight p {
  color: #666;
  font-size: 0.25rem;
}
.RightTitle {
  width: 50%;
  margin-left: 10%;
  height: 128px;
}
</style>