<template>
  <div class="view ppp">
    <div class="viewHead">
      <h1>{{content.title}}</h1>
      <div class="virwHeadTitle">
        <div class="ViewOther textPadding">
          <div class="ebLefCk le">
            <i class="ebLefCkImg"></i>
            <span class="ebLefCkText">{{content.views}}</span>
          </div>
          <div class="ebLefCk">
            <i class="ebLefDzImg"></i>
            <span class="ebLefCkText">{{content.like_count}}</span>
          </div>
          <div class="ebLefCk">
            <span class="ebLefCkText">{{content.date}}</span>
          </div>
        </div>
        <div class="textPadding">文章类别:{{content.classfiy}}</div>
      </div>
    </div>
    <div class="wordText" v-html="content.content">
      {{content.content}}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data:{}
    };
  },
  props:{
    content:{
      type:Object,
      default:{}
    }
  }
};
</script>
<style>
.view {
  width: 100%;
  color: rgba(0, 0, 0, 0.75);
  background-color: #fff;
  font-family: Courier, "arial block", "微软雅黑";
}
.viewHead H1 {
  font-weight: 800;
  color: #222226;
  word-break: break-all;
  word-wrap: break-word;
}
.view p {
  color: #4d4d4d;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 16px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-x: auto;
  word-break: break-all;
  word-wrap: break-word;
}
.view p img {
  cursor: zoom-in;
  margin: 24px 0;
  max-width: 100%;
  border-style: none;
}
.view h2 {
  color: #4f4f4f;
  margin: 8px 0 16px;
  font-weight: 600;
  word-break: break-word !important;
  font-variant-ligatures: no-common-ligatures;
}
.virwHeadTitle {
  background: #f8f8f8;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.ViewOther {
  display: flex;
}
.ebLefCk {
  margin-left: 1vw;
}
.le {
  margin-left: 0 !important;
}
.ViewOther .ebLefCkText {
  font-size: 0.25rem;
  color: #999aaa;
}
.textPadding {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* .ppp h2,h3,h4,h5{
font-weight: 600!important;
} */
</style>