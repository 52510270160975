<template>
  <div class="modal-bg" v-show="show">
    <div class="modal-container">
      <div class="modal-header"><slot></slot></div>
      <div class="modal-main" v-if="ts">
        <div class="xz" v-if="ts">
          <div class="mmm" v-for="(item, i) in data" :key="i">
            <div class="xz-li">
              <span>{{ item.classify }}</span>
              <button @click="reson === 0 ? submit(i) : sum(i)">选择</button>
            </div>
          </div>
          <div class="zcbt" style="margin-top: 10px">
            <div class="xz-li" v-if="reson === 0">
              <div class="input">
                <input
                  type="text"
                  placeholder="请输入分类"
                  v-model="inputData"
                />
              </div>
              <button @click="submits">创建</button>
            </div>
          </div>
        </div>
      </div>
      <div class="xz" v-else>
        <div class="zcbt" style="margin-top: 10px">
          <div class="xz-li">
            <div class="input">
              <input type="text" placeholder="请输入等级" v-model="limit" />
            </div>
            <button @click="updataLimit">修改</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button round @click="hideModal">返回</button>
        <button round @click="hideModals" v-if="!types&&ts" >保持原分类</button>
      </div>
    </div>
  </div>
</template>
<script>
// import repassword from './component/repassword'//引入密码修改模板
import { network } from "../assets/js/network";
export default {
  name: "modal",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    types: {
      type: Boolean,
      default: true,
    },
    reson: {
      type: Number,
      default: 0,
    },
    ts: {
      type: Boolean,
      default: true,
    },
    i:{
      type:Number,
      default:-1
    }
  },
  data() {
    return {
      code: 0,
      inputData: "",
      limit:''
    };
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
    },
    hideModals() {
      let obj = {
        code: 1,
      };
      this.$emit("submit", obj);
    },
    submit(i) {
      // console.log(this.data[i].classify);
      let obj = {
        classify: this.data[i].classify,
        code: !this.types ? 2 : 0,
      };
      // console.log(obj);
      this.$emit("submit", obj);
    },
    sum(i) {
      let obj = {
        classify: this.data[i].classify,
      };
      // console.log(this.data[i].classify);
      this.$emit("submit", obj);
    },
    submits() {
      let flys = 0;
      // console.log(this.inputData);
      let obj = {
        classify: this.inputData,
        code: !this.types ? 2 : 0,
      };
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].classify == this.inputData) {
          // console.log("重复");
          flys = 1;
          break;
        }
      }
      if (flys === 0) {
        this.$emit("submit", obj);
      } else {
        alert("已有此分类");
      }
    },
    updataLimit(){
    // console.log(this.i,"qqqq")
    var re = /^[1-9]+[0-9]*]*$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/
    if(this.limit>=0 && this.limit<=9&&re.test(this.limit)){
      // console.log(this.limit)
      let obj={
        i:this.i,
        limit:this.limit
      }
      this.$emit("submit", obj);
    }else{
      alert("请正确输入0~9数字")
    }
    }
  },
};
</script>
<style>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.modal-container {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header {
  height: 56px;
  background: #409eff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: move; */
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  border-top: 1px solid #ddd;
}
.modal-footer button {
  width: 100px;
  background: none;
  border: 1px solid #fff;
  cursor: pointer;
}
.modal-main {
  padding: 15px 40px;
}
.xz {
  width: 300px;
}
.xz-li {
  display: flex;
  justify-content: space-between;
}
.xz-li > span {
  font-size: 20px;
  font-weight: 500;
}
.xz-li > button {
  border: none;
  background: none !important;
  font-size: 15px;
  color: rgb(145, 141, 141);
}
.xz-li > button:hover {
  font-size: 20px;
  color: rgb(240, 148, 148);
}
.fgxs {
  width: 100%;
  height: 0.5px;
  background-color: #e8e8ee;
}
.mmm {
  margin-top: 10px;
}
.input {
  width: 75%;
  border: 1px solid #e8e8ee;
  border-radius: 10px;
}
.xz-li input {
  border: none;
  outline: none;
  width: 90%;
  margin-left: 5%;
  height: 30px;
}
</style>
