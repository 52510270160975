function formatTen(num) {
    return num > 9 ? (num + "") : ("0" + num);
}

export function times(date, i) {
    var date = new Date(date)
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    if (i === 1) {
        return year + "-" + formatTen(month) + "-" + formatTen(day);
    } else if(i===2){
          return year + "-" + formatTen(month) + "-" + formatTen(day) + " " + formatTen(hour) + ":" + formatTen(minute)
    }else {
        return year + "-" + formatTen(month) + "-" + formatTen(day) + " " + formatTen(hour) + ":" + formatTen(minute) + ":" + formatTen(second);
    }
}
